import React from 'react'
import ReactDOM from 'react-dom'
import { onRegister, registry } from './registry'

const log = (
  level: 'debug' | 'info' | 'warn' | 'error',
  ...args: unknown[]
) => {
  if (level === 'error') return console.error(...args)
  if (process.env.NODE_ENV === 'production') return
  console[level](...args)
}

type ReactHydrateOptions = {
  name?: string
  root?: string
}

export const hydrateReactComponents = ({
  name,
  root,
}: ReactHydrateOptions = {}) => {
  let query = '.vite-rails-react-component'
  if (name) query += `[data-component-name="${name}"]`
  if (root) query = root + ' ' + query
  const componentTag = document.querySelectorAll(query)
  componentTag.forEach((tag) => {
    try {
      hydrateComponent(tag)
    } catch (e) {
      log('error', `Error hydrating component ${tag.id}`, e)
    }
  })
}

const hydrateComponent = (scriptTag: Element) => {
  const name = scriptTag.getAttribute('data-component-name')
  const id = scriptTag.getAttribute('data-component-id')
  if (!id || !name) return

  const props = JSON.parse(scriptTag.innerHTML) as Record<string, unknown>
  const component = registry.get(name)
  if (!component) {
    log('warn', `Component ${name} not registered`)
    return
  }

  const node = document.getElementById(id)
  if (!node) return

  const element = React.createElement(component, props)
  if (node.hasChildNodes()) {
    ReactDOM.hydrate(element, node)
  } else {
    ReactDOM.render(element, node)
  }
}

onRegister((name) => hydrateReactComponents({ name }))
