import ReactDOM from 'react-dom'

const unmount = (el: Element): void => {
  const componentId = el.getAttribute('data-component-id') || ''
  const domNode = document.getElementById(componentId)
  if (domNode === null) return

  try {
    ReactDOM.unmountComponentAtNode(domNode)
  } catch (e: any) {
    console.error(
      `Caught error calling unmountComponentAtNode: ${e.message} for domNode`,
      domNode,
      e
    )
  }
}

export const unmountReactComponents = (): void => {
  const elements = document.getElementsByClassName('vite-rails-react-component')
  Array.from(elements).forEach(unmount)
}
